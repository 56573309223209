<template>
  <div class="flex justify-between items-center">
    <app-heading>
      {{ t('models.excursion', 2) }}
    </app-heading>
  </div>

  <div class="w-full mt-2 flex items-center">
    <form-control-text
      class="flex-1"
      name="excursions_search"
      :placeholder="t('excursions.index.search')"
      :icon-props="{ library: 'coolicon', name: 'search', classes: ['form-icon-theme'], size: 'text-2xl' }"
      @changed="handleSearch"
    />
  </div>

  <div class="w-full mt-4 mb-2 flex justify-between items-center">
    <excursion-filters
      class="w-6/12"
      @geoloc-updated="handleGeolocUpdated"
    />

    <app-button
      v-if="store.state.auth.user.authorizations?.excursions?.create"
      feature="create"
      @click="router.push({ name: 'create-excursions' })"
    />
  </div>

  <template v-if="!loading">
    <template
      v-if="excursions.length > 0"
    >
      <app-card-item
        v-for="(excursion, i) in excursions"
        :key="i"
        class="overflow-hidden my-4"
      >
        <excursion-card
          v-bind="excursion"
        />
      </app-card-item>
    </template>

    <div
      v-else
      class="mt-10 text-center border-1 border-gray-300 p-4 rounded-lg text-gray-500 shadow-lg shadow-black-200"
    >
      <p>
        {{ t('excursions.index.no_results_here') }}
      </p>

      <p
        v-if="!guestTravellerAccess"
      >
        <br>
        {{ t('excursions.index.try_more_filters') }}
      </p>
    </div>

    <call-to-register
      v-if="guestTravellerAccess"
      class="mt-6"
    />

    <template v-if="lastPage && page < lastPage">
      <a
        v-if="nextPageFetching === false"
        href="#"
        class="block text-center app-link w-full"
        @click.prevent="nextPage"
      >
        {{ t('common.actions.load_more') }}
      </a>
    </template>

    <div
      v-if="nextPageFetching"
      class="text-center w-full"
    >
      <app-spinner
        class="text-theme-500"
        size="lg"
      />
    </div>
  </template>

  <template v-else>
    <excursion-card-empty
      v-for="i in 7"
      :key="i"
      class="my-4"
    />
  </template>
</template>

<script setup>
import { ref, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import {
  fetchExcursions as fetchApiExcursions,
  destroyExcursion,
} from '@shared/http/api'
import useAccess from '@extranet/hooks/access'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'
import AppHeading from '@app/components/ui/AppHeading.vue'
import CallToRegister from '@app/components/resources/guest/CallToRegister.vue'
import FormControlText from '@shared/components/form/controls/FormControlText.vue'
import ExcursionCard from '@app/components/resources/excursion/ExcursionCard.vue'
import ExcursionCardEmpty from '@app/components/resources/excursion/ExcursionCardEmpty.vue'
import ExcursionFilters from '@app/components/resources/excursion/ExcursionFilters.vue'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const excursions = ref([])
const nextPageFetching = ref(false)
const page = ref(1)
const lastPage = ref(null)
const loading = ref(false)
const { guestTravellerAccess } = useAccess()

function fetchExcursions(nextPage = false) {
  return new Promise((resolve) => {
    if (nextPage === false) {
      loading.value = true
    }

    nextPageFetching.value = nextPage

    const params = {
      'page': page.value,
      'search': searchKeywords.value,
      'source': 'app',
      'use_geoloc_setting': true,
    }

    fetchApiExcursions(params)
      .then((response) => {
        const oldExcursions = excursions.value
        const newExcursions = response.data.data?.map((excursion) => ({
          resource: excursion,
          destroyCallback: () => handleDestroy(excursion.id),
        })) ?? []

        if (nextPage) {
          // Add new results after the old ones
          excursions.value = [...oldExcursions, ...newExcursions]
        } else {
          // Replace the old results with the new ones
          excursions.value = newExcursions
        }

        // Filtering excursion by favorite with geoloc
        excursions.value = excursions.value.sort((a, b) => {
          if (a.resource.attributes.favorite === b.resource.attributes.favorite) {
            return 0
          }

          return a.resource.attributes.favorite ? -1 : 1
        })

        lastPage.value = response.data.meta?.last_page
      })
      .catch(() => {})
      .finally(() => {
        nextPageFetching.value = false
        loading.value = false
        resolve()
      })
  })
}

function handleDestroy(id) {
  loading.value = true

  destroyExcursion(id).then(() => {
    resetPage()
    fetchExcursions()

    store.commit(
      'flashes/ADD_FLASH',
      {
        message: t('excursions.destroy.success'),
        type: 'success',
      },
    )
  })
}

function resetPage() {
  page.value = 1
}

function nextPage() {
  page.value += 1
  fetchExcursions(true)
}

const typingTimer = ref(0)
const searchKeywords = ref(null)

function handleSearch(value) {
  // Wait a little before emit the 'searched event'
  // it allows user to finish typing, and avoid to trigger an api call at each keystroke
  if (typingTimer.value) {
    clearTimeout(typingTimer.value)
  }

  typingTimer.value = setTimeout(() => {
    resetPage()
    searchKeywords.value = value
    fetchExcursions()
  }, 400)
}

async function handleGeolocUpdated() {
  resetPage()
  fetchExcursions()
}

// ---------- LIFECYCLES ----------

fetchExcursions()

onUnmounted(() => {
  store.commit('filters/RESET_FILTERS', 'excursions')
})
</script>
